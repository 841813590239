
import { Component, Vue } from 'vue-property-decorator'
import {httpService, tvhEnvironmentApi} from '@/util/HttpAdapter'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import KionInput from '@/components/Shared/Input/Input.vue'

@Component({
  components: {
    KionTemplate,
    KionButton,
    KionInput
  }
})
export default class KionCode extends Vue {
  qr: string | null = null
  phone = ''
  code = ''
  phoneError: string | null = null
  codeError: string | null = null
  loading = false
  mounted() {
    const q = this.$route.query
    if (typeof q?.pq === 'string') {
      this.qr = this.code = q.pq.toUpperCase()
    }
  }

  get isPhoneValid() {
    return /\+7 \(\d\d\d\) \d\d\d-\d\d-\d\d/.test(this.phone)
  }

  get isButtonEnable(): boolean {
    return !!this.code
  }

  webSsoAuth() {
      const hostUrl = window.location.host;
      window.location.assign(encodeURI(`https://${tvhEnvironmentApi}/tvh-public-api-gateway/public/rest/general/websso/redirect?state=${this.code.toUpperCase()}&clientType=WEB&domain=${hostUrl}`));
  }

   proceedToSMS() {
    httpService.phone = this.phoneToMSISDN(this.phone)
    httpService.promoCode = this.code
    this.loading = true
    this.$router.push('/sms')
  }

  phoneToMSISDN(phone: string): string {
    return phone.replace(/[+()_ -]/g, '')
  }

  setCodeErrors(error?: string) {
    this.code = this.code.trim()
    if (error) {
      this.codeError = error
      return
    }

    if (!this.code) {
      this.codeError = 'Обязательное поле'
      return
    }

    this.codeError = null
  }
}
